angular.module('MyHippoProducer.Controllers').directive('paymentInformationSection', function () {
    return {
        restrict: 'E',
        scope: {},
        templateUrl: 'pages/policy-details/sections/payment-information/payment-information.html',
        controller: function ($scope, PolicyService, WorkflowAdapter, $element, toaster, spinnerService) {
            $scope.PolicyService = PolicyService;
            $scope.policyInfo = PolicyService.policyInfo;
            $scope.escrowCheckout = WorkflowAdapter.getAllFieldsForSection('checkout_page', 'escrow_section');
            $scope.creditCardCheckout = WorkflowAdapter.getAllFieldsForSection('checkout_page', 'payment_method_section');
            $scope.achCheckout = WorkflowAdapter.getAllFieldsForSection('checkout_page', 'payment_method_section');

            // Create an instance of the card Element1
            const setCreditInfo = (creditInfo) => {
                $scope.creditInfo = creditInfo;
                if ($scope.creditInfo) {
                    $scope.expirationDate = `${('0' + creditInfo.exp_month).slice(-2)}/${creditInfo.exp_year}`;
                }
            };
            setCreditInfo($scope.policyInfo.checkout_data.cc_info);

            const setAchInfo = (achInfo) => {
                $scope.achInfo = achInfo;
            };

            setAchInfo($scope.policyInfo.checkout_data.ach_info);

            $scope.agree_ach_terms = false;
            $scope.confirm_account_match = true;

            const createCreditData = (stripe) => {
                // Create Credit Card Form
                const elements = stripe.elements();
                const baseStyle = {
                    base: {
                        color: 'black',
                        letterSpacing: '-0.6px',
                        fontSize: '20px',
                        height: '50px',
                        fontFamily: 'Mark OT',
                        lineHeight: '50px',
                        fontWeight: 'normal',
                    },
                    invalid: {},
                };
                const cardNumber = elements.create('cardNumber', {
                    style: baseStyle,
                    classes: {},
                });
                cardNumber.mount($element.find('#card-number')[0]);

                const cardExpiry = elements.create('cardExpiry', {
                    style: baseStyle,
                    classes: {},
                });
                cardExpiry.mount($element.find('#expiration-date')[0]);

                const cardCvc = elements.create('cardCvc', {
                    style: baseStyle,
                    classes: {},
                });
                cardCvc.mount($element.find('#cvc')[0]);

                return cardNumber;
            };

            const handleCreditSave = (stripe, cardNumber) => {
                stripe.createToken(cardNumber).then(result => {
                    if (result.error) {
                        toaster.pop('error', 'Credit Card Error', 'Incorrect Credit Card Information');
                    } else {
                        toaster.pop('success', 'Credit Card', 'Successfully added');
                        $scope.policyInfo.checkout_data.payment_token = result.token.id;
                        setCreditInfo(result.token.card);
                        $scope.toggleCreditCardEditMode();
                    }
                    spinnerService.hide('globalSpinner');
                });
            };

            const isIncompleteBankAccountInfo = function (bankAccountData) {
                return (
                    !_.get(bankAccountData, 'account_holder_name') ||
                    !_.get(bankAccountData, 'routing_number') ||
                    !_.get(bankAccountData, 'account_number') ||
                    !_.get(bankAccountData, 'confirm_account_number') ||
                    !$scope.accepts_ach_terms
                );
            };

            const handleAchSave = (stripe) => {
                const bankAccountData = {
                    country: 'US',
                    currency: 'usd',
                    account_holder_type: 'individual',
                    account_holder_name: $scope.account_holder_name,
                    routing_number: $scope.routing_number,
                    account_number: $scope.account_number,
                    confirm_account_number: $scope.confirm_account_number,
                    accepts_ach_terms: $scope.accepts_ach_terms,
                };

                if (!isIncompleteBankAccountInfo(bankAccountData)) {
                    stripe.createToken('bank_account', bankAccountData).then(result => {
                        if (result.error) {
                            toaster.pop('error', 'Bank Account (ACH) Error', 'Incorrect Bank Account (ACH) Information');
                        } else {
                            toaster.pop('success', 'Bank Account (ACH)', 'Successfully added');
                            $scope.policyInfo.checkout_data.payment_token = result.token.id;
                            $scope.policyInfo.checkout_data.accepts_ach_terms = $scope.accepts_ach_terms;
                            setAchInfo(result.token.bank_account);
                            $scope.toggleAchEditMode();
                        }
                        spinnerService.hide('globalSpinner');
                    });
                }
            };

            $element.ready(() => {
                const stripe = Stripe($scope.policyInfo.checkout_data.stripe_key);
                const cardNumber = createCreditData(stripe);
                $scope.toggleCreditCardEditMode = () => { $scope.isEditingCreditCard = !$scope.isEditingCreditCard; };
                $scope.toggleAchEditMode = () => { $scope.isEditingAch = !$scope.isEditingAch; };
                $scope.compare = function (confirm_account_number) {
                    $scope.confirm_account_match = $scope.account_number === confirm_account_number;
                };
                $scope.save = function () {
                    spinnerService.show('globalSpinner');
                    if ($scope.policyInfo.checkout_data.payment_method === 'cc') {
                        handleCreditSave(stripe, cardNumber);
                    } else if ($scope.policyInfo.checkout_data.payment_method === 'ach') {
                        handleAchSave(stripe);
                    }
                };
            });
        }
    };
});
